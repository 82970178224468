<script setup lang="ts">
  defineProps<{
    initializing?: boolean
  }>()

  const slots = useSlots()
</script>

<template>
  <div
    class="flex flex-col rounded-md bg-dark-200 ring-1 ring-primary-500 dark:bg-dark-800"
  >
    <div
      class="flex items-center justify-between gap-3 rounded-t-md bg-dark-50 px-1.5 py-1 dark:bg-dark-700"
    >
      <div class="font-semibold">
        <slot name="title" />
      </div>

      <div
        v-if="slots.actions"
        class="flex items-center gap-3"
      >
        <slot name="actions" />
      </div>
    </div>

    <div
      class="flex grow flex-col items-stretch overflow-hidden rounded-b-md px-1.5 py-1.5"
    >
      <NuxtLoadingLogo
        v-if="initializing"
        class="size-28 animate-pulse"
      />

      <slot
        v-else
        name="content"
      />
    </div>
  </div>
</template>
