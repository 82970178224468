import { consola } from 'consola'
import { defineStore, acceptHMRUpdate } from 'pinia'

export const useEnvironmentStore = defineStore('environment', () => {
  const { t } = useI18n()
  const route = useRoute()

  const stationSuggestionsNewCount = ref<number>(0)
  const stationSuggestionsCheck = async (): Promise<void> => {
    try {
      const { count } = await $request<{ count: number }>(
        '/api/stations/suggestions/count',
      )

      stationSuggestionsNewCount.value = count
    } catch (e) {
      if (import.meta.dev) {
        consola.error(e)
      }

      stationSuggestionsNewCount.value = 0
    }
  }

  const fuelSubscriptionsCheckPending = ref<boolean>(false)
  const fuelSubscriptionsCheck = async (): Promise<void> => {
    try {
      if (fuelSubscriptionsCheckPending.value) {
        return
      }

      fuelSubscriptionsCheckPending.value = true

      const { fuelUpdatesCount } = await $request<{
        fuelUpdatesCount: number
      }>('/api/fuels/prices/tracker/check')

      if (!!fuelUpdatesCount && route.path !== '/price-tracker') {
        useNotify(
          'primary',
          t(
            'station.fuel.price-tracker.notification.updates-available.message',
          ),
          {
            link: { to: '/price-tracker', text: t('global.label.view') },
            timeout: 10000,
          },
        )
      }
    } catch (e) {
      if (import.meta.dev) {
        consola.error(e)
      }
    } finally {
      fuelSubscriptionsCheckPending.value = false
    }
  }

  return {
    stationSuggestionsNewCount,
    stationSuggestionsCheck,

    fuelSubscriptionsCheck,
  }
})

import.meta.hot?.accept(acceptHMRUpdate(useEnvironmentStore, import.meta.hot))
