import { consola } from 'consola'
import { defineStore, acceptHMRUpdate } from 'pinia'
import { useAuthStore } from '~~/stores/auth'

export const useChatStore = defineStore('chat', () => {
  const { account } = storeToRefs(useAuthStore())

  const chatSupportUnreadIds = ref<string[]>([])
  const chatSupportUnreadCheck = async (): Promise<void> => {
    try {
      if (!account) {
        if (import.meta.dev) {
          consola.error('No account to check chats')
        }

        return
      }

      const { items } = await $request<{ items: string[] }>(
        '/api/chats-customers/unread',
      )
      chatSupportUnreadIds.value = items
    } catch (e) {
      chatSupportUnreadIds.value = []

      if (import.meta.dev) {
        consola.error(e)
      }
    }
  }

  return {
    chatSupportUnreadIds,
    chatSupportUnreadCheck,
  }
})

import.meta.hot?.accept(acceptHMRUpdate(useChatStore, import.meta.hot))
