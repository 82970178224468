<script setup lang="ts">
  import { useAuthStore } from '~~/stores/auth'
  import { useChatStore } from '~~/stores/chat'
  import { useEnvironmentStore } from '~~/stores/environment'
  import { AccountRoleType } from '~~/types/account'
  import {
    EVENT_CHAT_SUPPORT_TOGGLE,
    EVENT_MENU_NAV_SIDE_TOGGLE,
  } from '~~/types/event'
  import SidebarBase from '~/components/SidebarBase.vue'

  interface IMenuLink {
    to: string
    label?: string
    icon?: string
    count?: number
    indicator?: 'ping'
    acl?: AccountRoleType[keyof AccountRoleType][]
  }

  const props = defineProps<{
    compact?: boolean
  }>()

  const emit = defineEmits(['size-toggle'])

  const { t } = useI18n()
  const { isAdmin } = storeToRefs(useAuthStore())
  const { hasOneOfRoles, signOut } = useAuthStore()
  const { stationSuggestionsNewCount } = storeToRefs(useEnvironmentStore())

  const menuLinks = computed<IMenuLink[]>(() => {
    const links: IMenuLink[] = [
      {
        to: '/',
        label: t('nav.side.label.overview'),
        icon: 'icon-chart-bar-solid',
        acl: [AccountRoleType.ADMIN, AccountRoleType.CUSTOMER],
      },
    ]

    if (isAdmin.value) {
      links.push({
        to: '/price-monitor',
        label: t('nav.side.label.price-monitor'),
        icon: 'icon-display-solid',
        acl: [AccountRoleType.ADMIN],
      })
    }

    links.push({
      to: '/price-tracker',
      label: t('nav.side.label.price-tracker'),
      icon: 'icon-chart-finance',
      acl: [AccountRoleType.ADMIN, AccountRoleType.CUSTOMER],
    })

    links.push({
      to: '/stations',
      label: t('nav.side.label.stations'),
      icon: 'icon-station-solid',
      acl: [AccountRoleType.ADMIN, AccountRoleType.CUSTOMER],
    })

    if (isAdmin.value) {
      links.push({
        to: '/station-suggestions-new',
        label: t('nav.side.label.station-suggestions-new'),
        icon: 'icon-star-solid',
        count: stationSuggestionsNewCount.value,
        indicator: 'ping',
        acl: [AccountRoleType.ADMIN],
      })

      links.push({
        to: '/chats-users',
        label: t('nav.side.label.chats-users'),
        icon: 'icon-chat-bubble-left-right-solid',
        acl: [AccountRoleType.ADMIN],
      })

      links.push({
        to: '/customers',
        label: t('nav.side.label.customers'),
        icon: 'icon-users-solid',
        acl: [AccountRoleType.ADMIN],
      })

      links.push({
        to: '/invitations',
        label: t('nav.side.label.invitations'),
        icon: 'icon-user-plus-solid',
        acl: [AccountRoleType.ADMIN],
      })
    }

    links.push({
      to: '/settings',
      label: t('nav.side.label.settings'),
      icon: 'icon-cog-8-tooth-solid',
    })

    return links
  })
  const menuLinksFiltered = computed<IMenuLink[]>(() =>
    menuLinks.value.filter((item) => !item.acl || hasOneOfRoles(item.acl)),
  )

  const sidebarBaseTarget = ref<InstanceType<typeof SidebarBase> | null>(null)
  const { isMobile } = useWindow()
  const isModeCompact = computed<boolean>(
    () => !!props.compact && !isMobile.value,
  )

  const { chatSupportUnreadIds } = storeToRefs(useChatStore())
  const chatsToggle = (): void => {
    useEmitter().emit(EVENT_CHAT_SUPPORT_TOGGLE)
  }
</script>

<template>
  <SidebarBase
    ref="sidebarBaseTarget"
    :toggle-action="EVENT_MENU_NAV_SIDE_TOGGLE"
    position="left"
    :toggleable="isMobile"
  >
    <div class="relative flex min-h-screen flex-col">
      <div class="absolute inset-0 z-0">
        <SvgImage
          symbol="logo"
          class="absolute z-0 aspect-1 h-auto max-w-48 -rotate-[36deg] text-primary-300/80 dark:text-primary-500/50"
          :class="{
            'bottom-12 right-8': !isModeCompact,
            'bottom-20 left-1/2 -translate-x-1/2': isModeCompact,
          }"
        />

        <div
          class="absolute inset-0 z-10 bg-dark-300/50 backdrop-blur-lg dark:bg-dark-800/50"
        />
      </div>

      <div
        class="relative h-screen overflow-y-auto overflow-x-hidden"
        :class="{
          'px-4': !isModeCompact,
          'px-2': isModeCompact,
        }"
      >
        <div
          class="flex h-full flex-col gap-2 py-2 sm:py-4"
          :class="{
            'mx-auto max-w-12': isModeCompact,
          }"
        >
          <div>
            <LogoBase
              class="hidden size-12 shrink-0"
              :class="{
                'sm:block': isModeCompact,
              }"
            />

            <LogoFull
              class="h-12 w-auto shrink-0 text-black dark:text-dark-100"
              :class="{
                'sm:hidden': isModeCompact,
              }"
            />
          </div>

          <nav class="my-6 sm:my-10 md:my-12 xl:my-20">
            <div class="grid gap-2">
              <NuxtLink
                v-for="(
                  { to, label, icon, count, indicator }, linkIndex
                ) in menuLinksFiltered"
                :key="linkIndex"
                v-slot="{ isActive, href, navigate }"
                custom
                :to="to"
                active-class="active"
              >
                <a
                  :href="href"
                  class="relative flex items-center gap-3 overflow-hidden rounded-md px-3 py-3 text-sm font-semibold no-underline transition-all"
                  :class="{
                    'text-dark-500 hover:bg-dark-50 dark:text-dark-300 dark:hover:bg-dark-700 dark:hover:text-white':
                      !isActive,
                    'bg-primary-500 text-white dark:bg-primary-500': isActive,
                  }"
                  :title="label"
                  @click="navigate"
                >
                  <SvgImage
                    v-if="icon"
                    :symbol="icon"
                    class="size-6 shrink-0"
                  />

                  <span
                    class="whitespace-nowrap"
                    :class="{
                      'md:hidden': isModeCompact,
                    }"
                    >{{ label }}</span
                  >

                  <PingPoint
                    v-if="count && indicator === 'ping'"
                    class="absolute left-1.5 top-1.5 size-2 text-info-500"
                  />
                </a>
              </NuxtLink>
            </div>
          </nav>

          <div class="mt-auto">
            <button
              class="relative flex w-full items-center gap-3 overflow-hidden rounded-md p-3 text-sm font-semibold transition-all hover:bg-dark-50 dark:hover:bg-dark-700"
              :class="{
                'text-dark-600 dark:text-dark-300 dark:hover:text-dark-50':
                  !chatSupportUnreadIds.length,
                'animate-pulse text-primary-600 dark:text-primary-500':
                  chatSupportUnreadIds.length,
              }"
              :title="$t('chat.support.name.default')"
              @click="chatsToggle()"
            >
              <SvgImage
                symbol="icon-chat-bubble-left"
                class="size-6 shrink-0"
              />

              <span
                class="whitespace-nowrap"
                :class="{
                  'md:hidden': isModeCompact,
                }"
                >{{ $t('chat.support.name.default') }}</span
              >
            </button>

            <button
              class="relative flex w-full items-center gap-3 overflow-hidden rounded-md p-3 text-sm font-semibold text-danger-600 transition-all hover:bg-dark-50 dark:text-danger-500 dark:hover:bg-dark-700"
              :title="$t('account.label.sign-out')"
              @click="signOut()"
            >
              <SvgImage
                symbol="icon-arrow-left-on-rectangle"
                class="size-6 shrink-0"
              />

              <span
                class="whitespace-nowrap"
                :class="{
                  'md:hidden': isModeCompact,
                }"
                >{{ $t('account.label.sign-out') }}</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>

    <button
      class="absolute right-3 top-[18px] block size-7 cursor-pointer rounded-md bg-dark-300 p-1.5 dark:bg-dark-500/60 dark:hover:bg-dark-500 dark:hover:text-dark-50 sm:hidden"
      @click="sidebarBaseTarget?.close()"
    >
      <SvgImage symbol="icon-x-mark" />
    </button>

    <template #additional>
      <button
        class="absolute -right-2.5 top-[54px] z-30 hidden size-5 cursor-pointer rounded-full bg-dark-100 p-0.5 text-dark-400 transition-all hover:bg-primary-500 hover:text-white dark:bg-dark-600 dark:text-dark-300 dark:hover:bg-primary-400 dark:hover:text-white lg:block"
        @click="emit('size-toggle')"
      >
        <SvgImage
          :symbol="isModeCompact ? 'icon-chevron-right' : 'icon-chevron-left'"
        />
      </button>
    </template>
  </SidebarBase>
</template>
