<script setup lang="ts">
  import type { TEventKey } from '~~/types/event'

  const props = defineProps<{
    position: 'left' | 'right'
    toggleAction: TEventKey
    toggleable: boolean
  }>()

  const route = useRoute()
  // const { isMobile } = useWindow()

  const targetSidebarContent = ref<HTMLElement | null>(null)
  const isOpened = ref<boolean>(false)
  const isAnimated = ref<boolean>(false)

  const transitionName = computed<'sidebar-left' | 'sidebar-right'>(() =>
    props.position === 'left' ? 'sidebar-left' : 'sidebar-right',
  )
  const isLeft = computed<boolean>(() => props.position === 'left')
  const isRight = computed<boolean>(() => props.position === 'right')

  const open = (): void => {
    isOpened.value = true
    document.body.classList.add('overflow-hidden')
    setTimeout(() => (isAnimated.value = true), 0)
  }
  const close = (): void => {
    isAnimated.value = false
    setTimeout(() => {
      isOpened.value = false
    }, 200)
  }
  const toggle = (): void => {
    if (!isOpened.value) {
      open()
    } else {
      close()
    }
  }

  onMounted(() => {
    useEmitter().on(props.toggleAction, toggle)
  })
  onBeforeUnmount(() => {
    if (isOpened.value) {
      close()
    }

    document.body.classList.remove('overflow-hidden')
  })

  onClickOutside(targetSidebarContent, close)

  defineExpose({
    open,
    close,
    toggle,
  })

  watch(
    () => route.path,
    () => {
      if (isOpened.value) {
        close()
      }
    },
    { immediate: true },
  )
</script>

<template>
  <aside
    v-if="!toggleable || isOpened"
    class="fixed transition-all"
    :class="{
      'left-0 top-0 h-full': !toggleable,
      'inset-0': toggleable,
    }"
  >
    <Transition
      name="overlay"
      mode="out-in"
    >
      <div
        v-if="toggleable && isAnimated"
        class="absolute inset-0 bg-dark-500/30 backdrop-blur-[7px] dark:bg-dark-800/80"
      />
    </Transition>

    <Transition
      :name="transitionName"
      mode="out-in"
    >
      <div
        v-if="!toggleable || isAnimated"
        ref="targetSidebarContent"
        class="relative z-20 h-full overflow-y-auto overflow-x-hidden bg-dark-50 transition-all dark:bg-dark-800 xs:max-w-72"
        :class="{
          'rounded-r-lg': toggleable && isLeft,
          'ml-auto rounded-l-lg': toggleable && isRight,
        }"
      >
        <slot />
      </div>
    </Transition>

    <slot name="additional" />
  </aside>
</template>

<style lang="scss" scoped>
  .sidebar {
    &-left,
    &-right {
      &-enter-active,
      &-leave-active {
        @apply transition duration-200 ease-in-out;
      }
    }

    &-left {
      &-enter-to,
      &-leave-from {
        @apply translate-x-0 opacity-100;
      }

      &-enter-from,
      &-leave-to {
        @apply -translate-x-80 opacity-0;
      }
    }

    &-right {
      &-enter-from,
      &-leave-to {
        @apply translate-x-80 opacity-0;
      }

      &-enter-to,
      &-leave-from {
        @apply translate-x-0 opacity-100;
      }
    }
  }
</style>
