<script setup lang="ts">
  import { useAuthStore } from '~~/stores/auth'
  import { useChatStore } from '~~/stores/chat'
  import { useEnvironmentStore } from '~~/stores/environment'
  import { EVENT_CHAT_SUPPORT_TOGGLE } from '~~/types/event'

  const { isAdmin, isCustomer, isAuthenticated, isVerified } =
    storeToRefs(useAuthStore())

  const route = useRoute()
  const { isFullScreen, isSidebarCompact } = useLayout()
  const { isDesktop, isTablet } = useWindow()

  const { fuelSubscriptionsCheck, stationSuggestionsCheck } =
    useEnvironmentStore()
  useIntervalRefresh(fuelSubscriptionsCheck, 60000)

  if (isAdmin.value) {
    useIntervalRefresh(stationSuggestionsCheck, 3600000)
  }

  const { chatSupportUnreadCheck } = useChatStore()
  useIntervalRefresh(chatSupportUnreadCheck, 60000)

  const showChatSupportCustomer = ref<boolean>(false)
  const showChatSupportAdmin = ref<boolean>(false)
  const chatsToggle = (): void => {
    if (isAdmin.value) {
      showChatSupportAdmin.value = true
    } else if (isCustomer.value) {
      showChatSupportCustomer.value = true
    }
  }

  watchEffect(() => {
    if (!isAuthenticated.value) {
      const queryRedirect =
        route.fullPath !== '/' && !route.fullPath.startsWith('/auth/sign-in')
          ? `?redirect=${route.fullPath}`
          : ''

      return navigateTo(`/auth/sign-in${queryRedirect}`)
    }

    if (!isVerified.value) {
      return navigateTo('/auth/sign-verify')
    }

    if (isVerified.value && route.path.startsWith('/auth')) {
      return navigateTo((route.query.redirect as string) || '/')
    }
  })

  onMounted(() => {
    useEmitter().on(EVENT_CHAT_SUPPORT_TOGGLE, chatsToggle)
  })
</script>

<template>
  <div class="relative min-h-screen bg-dark-50 dark:bg-dark-900">
    <div
      v-if="!isAuthenticated || !isVerified"
      class="flex h-screen w-screen items-center justify-center py-20"
    >
      <NuxtLoadingLogo
        class="size-20"
        :spinner="true"
      />
    </div>

    <template v-else>
      <NavDashboardSide
        v-if="!isFullScreen"
        :compact="isTablet || isSidebarCompact"
        class="z-20 w-full"
        :class="{
          'sm:w-20': isTablet,
          'lg:w-72': !isSidebarCompact,
          'lg:w-20': isDesktop && isSidebarCompact,
        }"
        @size-toggle="isSidebarCompact = !isSidebarCompact"
      />

      <main
        class="relative z-10 flex min-h-screen grow transition-all"
        :class="{
          'ml-20': isTablet,
          'lg:ml-72': !isFullScreen && !isSidebarCompact,
          'lg:ml-20': !isFullScreen && isSidebarCompact,
        }"
      >
        <slot />
      </main>

      <NotifyList class="right-6 top-6 z-50 w-96" />

      <ClientOnly>
        <Teleport to="#teleports">
          <ReleaseNotesModal />

          <ChatSupportCustomer
            v-if="isCustomer && showChatSupportCustomer"
            class="fixed inset-0 z-30 h-screen w-screen pb-2 sm:bottom-1 sm:right-1 sm:mb-2 sm:ml-auto sm:mr-2 sm:mt-auto sm:max-h-[600px] sm:max-w-screen-xxs sm:pb-0 sm:shadow-xl"
            @close="showChatSupportCustomer = false"
          />

          <ChatSupportAdmin
            v-if="isAdmin && showChatSupportAdmin"
            class="fixed inset-0 z-30 h-screen w-screen pb-2 sm:bottom-1 sm:right-1 sm:mb-2 sm:ml-auto sm:mr-2 sm:mt-auto sm:max-h-[600px] sm:max-w-screen-xxs sm:pb-0 sm:shadow-xl"
            @close="showChatSupportAdmin = false"
          />
        </Teleport>
      </ClientOnly>
    </template>
  </div>
</template>
