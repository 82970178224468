<script setup lang="ts">
  import { useChatStore } from '~~/stores/chat'
  import type { IAccount } from '~~/types/account'
  import type { IChat } from '~~/types/chat'
  import type { ICustomer } from '~~/types/customer'
  import type { IRecordResponse } from '~~/types/record'
  import type { IUser } from '~~/types/user'

  const emit = defineEmits(['close'])

  const endpoint = 'chats-customers'

  const customersListFilters = ref<{
    search: string
  }>({
    search: '',
  })
  const {
    items: customersList,
    container: customersListContainer,
    pending: customersListPending,
  } = await useRecordListInfinite<ICustomer>(
    'customers',
    {
      refreshOnLoad: false,
    },
    customersListFilters,
  )
  const initializing = computed<boolean>(
    () => customersListPending.value && !customersList.value.length,
  )

  const { chatSupportUnreadIds } = storeToRefs(useChatStore())

  const chatTarget = ref<{
    item: IChat | undefined
    pending?: boolean
  }>({
    item: undefined,
    pending: false,
  })
  const chatCurrentReset = (): void => {
    chatTarget.value.item = undefined
    chatTarget.value.pending = false
  }
  const chatCurrent = computed<IChat | undefined>(() => chatTarget.value.item)
  const chatCurrentPresence = ref<string[]>([])
  const isChatCurrentCustomerOnline = computed(
    () =>
      !!chatCurrent.value &&
      chatCurrentPresence.value.includes(chatCurrent.value.createdById),
  )
  const chatCurrentCreator = computed<IAccount | IUser | undefined>(
    () =>
      chatCurrent.value?.createdBy.account || chatCurrent.value?.createdBy.user,
  )
  const { displayName: chatCurrentCreatorName } =
    useChatCreator(chatCurrentCreator)
  const chatCurrentInit = async (customer: ICustomer): Promise<void> => {
    try {
      if (chatTarget.value.pending) {
        return
      }

      chatTarget.value.pending = true

      const { item: chat } = await $request<IRecordResponse<IChat>>(
        `/api/chats-customers/support/${customer.account.id}`,
      )

      chatTarget.value.item = chat
      chatSupportUnreadIds.value = chatSupportUnreadIds.value.filter(
        (id) => id !== chat.createdById,
      )
    } catch (e) {
      chatCurrentReset()
      useLogger().debug(e)
    } finally {
      chatTarget.value.pending = false
    }
  }
</script>

<template>
  <ChatSupportLayout :initializing="initializing || chatTarget.pending">
    <template #title>
      <div v-if="!chatCurrent">{{ $t('chat.support.name.admin') }}</div>

      <div
        v-else
        class="flex items-center gap-2"
      >
        <ActionButtonIcon
          icon="icon-arrow-left"
          @click="chatCurrentReset()"
        />

        <div>{{ chatCurrentCreatorName }}</div>

        <div
          class="size-2 rounded-full"
          :class="{
            'bg-dark-400': !isChatCurrentCustomerOnline,
            'bg-success-400': isChatCurrentCustomerOnline,
          }"
        />
      </div>
    </template>

    <template #actions>
      <ActionButtonIcon
        icon="icon-x-mark"
        @click="emit('close')"
      />
    </template>

    <template #content>
      <template v-if="!chatCurrent">
        <div
          ref="customersListContainer"
          class="flex grow flex-col gap-2 overflow-y-auto overflow-x-hidden"
        >
          <CustomerListFilters
            v-model:search="customersListFilters.search"
            :disabled="customersListPending"
          />

          <div class="size-full space-y-2">
            <ChatListCustomerItem
              v-for="(customer, index) in customersList"
              :key="index"
              :customer="customer"
              :unread="chatSupportUnreadIds.includes(customer.account.id)"
              @click="chatCurrentInit(customer)"
            />

            <template v-if="customersListPending">
              <SkeletonInner
                v-for="n in 10"
                :key="n"
                class="h-[60px] w-full animate-pulse"
              />
            </template>
          </div>

          <RecordListEmptyCard
            v-if="!customersList.length"
            class="text-sm"
          >
            {{ $t('chat.message.chat-list-empty') }}
          </RecordListEmptyCard>
        </div>
      </template>

      <ChatSocket
        v-else
        :chat="chatCurrent"
        :endpoint="endpoint"
        :use-suggestions="true"
        class="max-h-[calc(100vh-156px)] grow"
        @online="(value) => (chatCurrentPresence = value)"
      />
    </template>
  </ChatSupportLayout>
</template>
