import {
  WINDOW_BREAKPOINT_DESKTOP,
  WINDOW_BREAKPOINT_MOBILE,
} from '~~/types/window'

export const useWindow = () => {
  const { width: windowWidth } = useWindowSize()

  const isDesktop = computed<boolean>(
    () => windowWidth.value >= WINDOW_BREAKPOINT_DESKTOP,
  )
  const isMobile = computed<boolean>(
    () => windowWidth.value < WINDOW_BREAKPOINT_MOBILE,
  )
  const isTablet = computed<boolean>(
    () =>
      windowWidth.value >= WINDOW_BREAKPOINT_MOBILE &&
      windowWidth.value < WINDOW_BREAKPOINT_DESKTOP,
  )

  return {
    isDesktop,
    isMobile,
    isTablet,
  }
}
