<script setup lang="ts">
  import type { IChat } from '~~/types/chat'

  const props = defineProps<{
    chat: IChat
    endpoint: string
    messagingDisabled?: boolean
    useSuggestions?: boolean
  }>()

  const emit = defineEmits<{
    back: []
    close: []
    online: [value: string[]]
  }>()

  const slots = useSlots()

  const chatRef = toRef(() => props.chat)

  const {
    initializing,
    isReplySubmit,
    messages,
    messagesContainer,
    online,
    sendMessage,
  } = useChatSocket(chatRef, props.endpoint)
  watch(online, () => emit('online', online.value), { deep: true })

  const isAboutView = ref<boolean>(false)

  const isSuggestionSelecting = ref<boolean>(false)
  const suggestionSelected = ref<string | undefined>(undefined)
  const suggestionInit = (): void => {
    if (props.useSuggestions) {
      suggestionSelected.value = ''
      isSuggestionSelecting.value = true
    }
  }
  const suggestionSelect = (value: string): void => {
    suggestionSelected.value = value
    isSuggestionSelecting.value = false
  }
</script>

<template>
  <div class="flex grow flex-col overflow-hidden">
    <template v-if="isAboutView">
      <slot name="about" />
    </template>

    <template v-else-if="!isSuggestionSelecting">
      <div
        ref="messagesContainer"
        class="flex h-0 grow flex-col gap-5 overflow-y-auto overflow-x-hidden px-1"
      >
        <template v-if="initializing">
          <SkeletonInner
            v-for="i in 4"
            :key="i"
            class="h-20 w-full max-w-[80%] animate-pulse"
            :class="{
              'ml-auto opacity-60': i % 2 === 0,
            }"
          />
        </template>

        <template v-if="messages.length">
          <ChatMessage
            v-for="(chatMessage, index) in messages"
            :key="index"
            :chat-message="chatMessage"
            :reply="chat.createdById !== chatMessage.createdById"
          />
        </template>

        <RecordListEmptyCard
          v-else-if="!messages.length && !initializing"
          icon="icon-chat-bubble-left-solid"
          class="text-sm"
        >
          {{ $t('chat.label.no-messages') }}
        </RecordListEmptyCard>
      </div>

      <template v-if="!messagingDisabled">
        <ChatMessageForm
          v-if="!initializing"
          :disabled="isReplySubmit"
          :suggestion="suggestionSelected"
          class="mt-3"
          @submit="sendMessage"
        >
          <template
            v-if="useSuggestions"
            #buttons
          >
            <button
              type="button"
              class="relative flex items-center gap-1 rounded-e-md bg-primary-500 p-2 text-sm font-semibold text-white transition-all hover:bg-primary-400 disabled:opacity-60 disabled:hover:bg-primary-500"
              :title="$t('chat.label.suggest')"
              @click.prevent="suggestionInit()"
            >
              <SvgImage
                symbol="icon-light-bulb-solid"
                class="size-5 shrink-0"
              />
            </button>
          </template>
        </ChatMessageForm>

        <SkeletonInner
          v-else
          class="h-11 w-full animate-pulse"
        />
      </template>

      <slot
        v-else-if="slots['disabled-message']"
        name="disabled-message"
      />
    </template>

    <ChatMessageSuggestionSelect
      v-else-if="useSuggestions"
      class="pb-3"
      @cancel="isSuggestionSelecting = false"
      @select="suggestionSelect"
    />
  </div>
</template>
