<script setup lang="ts">
  import { useAuthStore } from '~~/stores/auth'
  import type { IChat } from '~~/types/chat'

  const emit = defineEmits(['close'])

  const endpoint = 'chats-customers'

  const { account } = storeToRefs(useAuthStore())
  const chatCurrentPresence = ref<string[]>([])
  const isAdminOnline = computed<boolean>(() =>
    chatCurrentPresence.value.some((id) => id !== account.value?.id),
  )

  const { data, pending } = await useRecord<IChat>(
    'chats-customers/support',
    account.value?.id,
  )
</script>

<template>
  <ChatSupportLayout :initializing="pending">
    <template #title>
      <div class="flex items-center gap-2">
        <div>{{ $t('chat.support.name.customer') }}</div>

        <div
          class="size-2 rounded-full"
          :class="{
            'bg-dark-400': !isAdminOnline,
            'bg-success-400': isAdminOnline,
          }"
        />
      </div>
    </template>

    <template #actions>
      <ActionButtonIcon
        icon="icon-x-mark"
        @click="emit('close')"
      />
    </template>

    <template #content>
      <ChatSocket
        v-if="data?.item"
        :chat="data.item"
        :endpoint="endpoint"
        :no-header="true"
        class="mb-1 h-[560px] px-1 pt-1"
        @online="(value) => (chatCurrentPresence = value)"
      />
    </template>
  </ChatSupportLayout>
</template>
