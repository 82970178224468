<script setup lang="ts">
  import type { ParsedContent } from '@nuxt/content'
  import { useAuthStore } from '~~/stores/auth'

  const { releaseNotesVersionCheck } = useAuthStore()
  const { releaseNotesVersionChecked } = storeToRefs(useAuthStore())

  const releaseNotesContent = ref<ParsedContent | null>(null)
  const versionChecked = ref<string | undefined>(
    releaseNotesVersionChecked.value,
  )
  const dateCheck = new Date()
  dateCheck.setMonth(dateCheck.getMonth() + 1)

  const { data } = await useLazyAsyncData('release-notes', () =>
    queryContent('/_partials/release-notes')
      .sort({ version: -1 })
      .where({ _partial: true })
      .findOne(),
  )

  const close = (): void => {
    if (releaseNotesContent.value) {
      versionChecked.value = releaseNotesContent.value.version
      releaseNotesVersionCheck(releaseNotesContent.value.version)
    }
  }

  watch(
    data,
    (value) => {
      if (value && value.date && new Date(value.date) < dateCheck) {
        releaseNotesContent.value = value
      }
    },
    { deep: true },
  )
</script>

<template>
  <ModalDefault
    v-if="releaseNotesContent && releaseNotesContent.version !== versionChecked"
    id="changelog"
    :opened="!!releaseNotesContent"
    @close="close()"
  >
    <template #title>{{ releaseNotesContent.title }}</template>

    <ContentRenderer :value="releaseNotesContent" />
  </ModalDefault>
</template>
