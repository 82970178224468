<script setup lang="ts">
  defineProps<{
    disabled?: boolean
  }>()

  const search = defineModel<string>('search')
</script>

<template>
  <form @submit.prevent="">
    <fieldset :disabled="disabled">
      <div class="grid grid-cols-1 gap-3">
        <div class="relative">
          <input
            v-model.lazy="search"
            class="peer order-2 w-full rounded border-0 bg-transparent pr-9 outline-none ring-1 ring-inset ring-dark-300 transition-all placeholder:text-sm placeholder:text-dark-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 disabled:opacity-80 dark:ring-dark-400 dark:focus:ring-primary-500"
            type="text"
            :placeholder="$t('customer.filter.search.placeholder')"
          />

          <SvgImage
            v-if="!disabled"
            symbol="icon-magnifying-glass"
            class="absolute right-2 top-2.5 size-5"
          />
        </div>
      </div>
    </fieldset>
  </form>
</template>
