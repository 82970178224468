<template>
  <svg
    width="801"
    height="800"
    viewBox="0 0 801 800"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.611328 153.6C0.611328 99.8349 0.611328 72.9524 11.0747 52.4169C20.2785 34.3534 34.9647 19.6672 53.0282 10.4634C73.5638 0 100.446 0 154.211 0H647.011C700.776 0 727.659 0 748.194 10.4634C766.258 19.6672 780.944 34.3534 790.148 52.4169C800.611 72.9524 800.611 99.835 800.611 153.6V646.4C800.611 700.165 800.611 727.048 790.148 747.583C780.944 765.647 766.258 780.333 748.194 789.537C727.659 800 700.776 800 647.011 800H154.211C100.446 800 73.5638 800 53.0282 789.537C34.9647 780.333 20.2785 765.647 11.0747 747.583C0.611328 727.048 0.611328 700.165 0.611328 646.4V153.6Z"
      fill="url(#paint0_linear_3960_354473)"
    />
    <path
      d="M484.262 260.999L477.713 252.062C462.329 267.463 400.79 339.914 346.366 423.968C345.594 425.133 345.199 426.503 345.233 427.896C345.268 429.288 345.729 430.638 346.557 431.765L366.558 459.528C367.218 460.44 368.092 461.18 369.105 461.684C370.118 462.189 371.24 462.442 372.373 462.424C373.507 462.405 374.62 462.115 375.615 461.578C376.611 461.041 377.46 460.273 378.089 459.34C389.434 442.039 400.973 425.301 412.322 409.329L432.323 423.209C433.677 424.158 435.328 424.602 436.982 424.464C438.637 424.325 440.188 423.611 441.361 422.45L487.535 366.349C507.15 401.147 519.266 434.045 519.266 460.669C519.266 491.837 506.745 521.728 484.457 543.767C462.169 565.806 431.94 578.188 400.42 578.188C368.901 578.188 338.672 565.806 316.384 543.767C294.096 521.728 281.575 491.837 281.575 460.669C281.575 415.6 316.766 352.469 363.488 292.187L363.886 291.794C375.616 276.77 387.923 261.939 400.615 247.678L400.806 247.866C412.735 233.986 425.615 218.962 437.731 205.46C425.038 191.387 412.54 178.266 400.615 166.667C326.957 239.129 225.611 364.827 225.611 460.287C225.611 506.182 244.049 550.197 276.868 582.65C309.687 615.102 354.198 633.334 400.611 633.334C447.024 633.334 491.536 615.102 524.355 582.65C557.174 550.197 575.611 506.182 575.611 460.287C575.416 399.059 533.494 325.271 484.262 260.999Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3960_354473"
        x1="0.611328"
        y1="0"
        x2="902.416"
        y2="104.372"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FD3118" />
        <stop
          offset="1"
          stop-color="#F89A12"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
